
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css';
import {mapActions} from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      sliders: [],
      swiperOptions: {
        // loop: true,
        lazy: true,
        preloadImages: false,
        autoplay: {
          delay: 5000,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        watchOverflow: true
      }
    }
  },
  async fetch() {
    this.sliders = await this.fetchSliders().then((result) => {
      result.filter((element) => {
        return element.media && element.media.data;
      })
      return result
    });
  },
  fetchOnServer: false,
  methods: {
    ...mapActions({
      fetchSliders: 'sliders/fetchSliders'
    }),
    sliderImage(slider) {
      if (slider && slider.media && slider.media.data && slider.media.data.urls) {
        return slider.media.data.urls.large
      }
      return '/bg.jpg'
    }
  }
}
